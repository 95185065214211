
export default {
    mounted() {
        this.$store.commit('eventFilters/filtersVisible', false);
    },

    activated() {
        // Should match mount
        this.$store.commit('eventFilters/filtersVisible', false);
    },

    destroyed() {
        this.$store.commit('eventFilters/filtersVisible', false);
    },

    deactivated() {
        // Should match destroy
        this.$store.commit('eventFilters/filtersVisible', false);
    }
};
