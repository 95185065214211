import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2d1c7e34"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHero: require('/builds/WeArePublic/wap-app/components/Base/Hero/Hero.vue').default,EventSlider: require('/builds/WeArePublic/wap-app/components/Event/Slider/EventSlider.vue').default,BaseSection: require('/builds/WeArePublic/wap-app/components/Base/Section/BaseSection.vue').default,QueryEventUser: require('/builds/WeArePublic/wap-app/components/Query/QueryEventUser.vue').default,FlexibleSections: require('/builds/WeArePublic/wap-app/components/FlexibleSections/FlexibleSections.vue').default,TemplateDefault: require('/builds/WeArePublic/wap-app/components/Template/Default/TemplateDefault.vue').default,QueryHome: require('/builds/WeArePublic/wap-app/components/Query/Home.vue').default})
