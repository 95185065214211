
import getHomeQuery from '~/graphql/queries/Home.graphql';
import QueryMixin from '~/mixins/query.js';

export default {
    mixins: [
        QueryMixin
    ],

    fetchKey: 'query-home',

    async fetch() {
        const { data } = await this.craftQuery(getHomeQuery);
        await this.setEntryOrNotFound(data);
    },

    head() {
        return this.seo;
    },

    computed: {
        heroImage() {
            if (!this.entry) {
                return;
            }

            if (this.entry.heroImages && this.entry.heroImages.length > 0) {
                return this.entry.heroImages[0];
            }

            return null;
        }
    }
};
