
export default {
    props: {
        query: {
            type: Object,
            default() {
                return {};
            }
        },

        fetchOnServer: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            events: null,
            totalResults: null,
            currentPageNumber: 1,
            lastPageNumber: null,
            notFound: false,
        };
    },

    fetchOnServer: true,

    async fetch() {
        const data = await this.fetchEvents();

        if (data) {
            this.events = data.events;
            this.totalResults = data.meta.total;
            this.lastPageNumber = data.meta.lastPage;

            if (this.totalResults === 0) {
                this.$emit('hide');
            } else {
                this.$emit('show');
            }
        } else {
            this.notFound = true;
        }
    },

    computed: {
        needsUpdate() {
            return this.$store.state.events.myListHomeChanged;
        },

        startDateFilterState() {
            if (!this.$route.query.startDate) {
                return;
            }
            return this.$route.query.startDate;
        },

        cityFilterState() {
            if (!this.$route.query.city) {
                return;
            }

            return parseInt(this.$route.query.city, 10);
        },

        regionFilterState() {
            if (!this.$route.query.region) {
                return;
            }

            return parseInt(this.$route.query.region, 10);
        },

        loadingState() {
            if (this.$fetchState.error || this.notFound) {
                return 'error';
            }

            if (!this.$fetchState.pending || this.data) {
                return 'ready';
            }

            return 'loading';
        },

        filterQuery() {
            const filters = {
                preset: 'user-saved',
            };

            this.applyCityFilter(filters);
            this.applyRegionFilter(filters);
            this.applyStartDateFilter(filters);

            return JSON.stringify({
                q: filters,
                limit: 50
            });
        }
    },

    watch: {
        filterQuery() {
            this.$fetch();
        },

        needsUpdate() {
            this.$fetch();
        }
    },

    methods: {
        async fetchEvents() {
            const { data } = await this.$axios({
                method: 'post',
                url: '/v2.0/events',
                params: {
                    page: this.currentPageNumber
                },
                data: this.filterQuery,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return data;
        },

        async loadMore() {
            ++this.currentPageNumber;

            const data = await this.fetchEvents();

            this.events.push.apply(this.events, data.events);
        },

        applyStartDateFilter(filterObject) {
            if (this.query.startDate) {
                filterObject.startDate = this.query.startDate;
            }

            if (!this.startDateFilterState) {
                return;
            }

            filterObject.startDate = this.startDateFilterState;
        },

        applyCityFilter(filterObject) {
            this.addLookupFilter('city', filterObject);

            if (!this.cityFilterState || filterObject.city.includes(this.cityFilterState)) {
                return;
            }

            filterObject.city.push(this.cityFilterState);
        },

        applyRegionFilter(filterObject) {
            this.addLookupFilter('region', filterObject);

            if (!this.regionFilterState || filterObject.region.includes(this.regionFilterState)) {
                return;
            }

            filterObject.region.push(this.regionFilterState);
        },

        addLookupFilter(propertyName, filterObject) {
            let value = [];

            if (this.query[propertyName] && this.query[propertyName].length > 0) {
                value = this.query[propertyName].map(object => object.laravelModelId);
            }

            filterObject[propertyName] = value;
        }
    }
};
